import React, { useState } from "react";
import { Grid } from "@mui/material";
import ScriptExpandingCard from "./AssistantTrainScriptCard";
import CallOpeningExpandingCard from "./AssistantTrainCallOpeningCard";
import { GTAGS } from "../../../data/configs/constants";
import { useSelector } from "react-redux";

const AssistantTraining = ({
  preBot,
  comCorrection,
  setComCorrection,
  mentionList,
  handleChange,
  assistant,
  isSaveClicked,
  setIsSavedClicked,
  fromOnboarding,
  handleSaveChanges,
}) => {
  const [goalExpanded, setGoalExpanded] = useState(false);
  const [scriptExpanded, setScriptExpanded] = useState(true);
  const user = useSelector((state) => state.authDetails.user);

  const handleCallOpeningExpandClick = () => {
    setGoalExpanded(!goalExpanded);
  };
  const handleScriptExpandClick = () => {
    setScriptExpanded(!scriptExpanded);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CallOpeningExpandingCard
          comCorrection={comCorrection}
          setComCorrection={setComCorrection}
          isExpanded={goalExpanded}
          handleExpandClick={handleCallOpeningExpandClick}
          cardTitle="CALL OPENING MESSAGE"
          handleChange={() =>{
            window.gtag("event", GTAGS.CALL_OPENING_MESSAGE_CHANGED, {
              userID: user.userId,
            });
            handleChange();
         }}
          assistant={assistant}
          mentionList={mentionList}
        />
      </Grid>
      <Grid item xs={12}>
        <ScriptExpandingCard
          handleSaveChanges={handleSaveChanges}
          mentionList={mentionList}
          isExpanded={true}
          handleExpandClick={handleScriptExpandClick}
          cardTitle="SCRIPT"
          handleChange={handleChange}
          script={assistant?.script}
          assistant={assistant}
          isSaveClicked={isSaveClicked}
          setIsSavedClicked={setIsSavedClicked}
          fromOnboarding={fromOnboarding}
          preBot={preBot}
        />
      </Grid>
    </Grid>
  );
};

export default AssistantTraining;
