import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Paper,
  Box,
  Card,
  Slider,
  styled,
  Switch,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Chip,
  Checkbox,
} from "@mui/material";
import {
  LANG_COUNTRY,
  LANGUAGES_LIST,
  LOCAL_STORAGE,
  MULTI_LANG_CODES,GTAGS
} from "../../../data/configs/constants";
import * as _ from "lodash";
import VoiceAssistant from "./VoiceAssistant";
import CustomHintPopover from "../../CustomHintPopover";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import VoiceMail from "../../VoiceMail";
import BaseModelMenu from "./VoiceModelElevenLabs";
import { getElevenLabVoices } from "../../../data/store/integrationSlice";
import { enqueueSnackbar } from "notistack";
import GenerateElevenLabsAudioSample from "./GenerateElevenLabsAudioSample";
import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from "../../../data/configs/utils";

const CustomizationComponent = ({
  handleChange,
  assistant,
  setElevenlabsGeneratedVoices,
  elevenlabsGeneratedVoices,
  setIsChanged,
  elevenlabsClonedVoiceAgreement,
  setElevenlabsClonedVoiceAgreement,
  abortSaving,
}) => {
  const user = useSelector((state) => state.authDetails.user);
  const installedAppNameArray = user?.installedApps;
  const dispatch = useDispatch();
  const [multiLangCodes, setMultiLangCodes] = useState(MULTI_LANG_CODES);
  const [sampleAudioUrl, setSampleAudioUrl] = useState(null);
  const [sampleAudioLoading, setSampleAudioLoading] = useState(false);
  const [updatedData, setUpdatedData] = useState(assistant?.ssml_data);
  const workspaceId = useSelector(
    (state) => state.authDetails?.user?.workspace
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    assistant?.ssml_data?.lang?.toLowerCase() ?? "en"
  );
  const [selectedGender, setSelectedGender] = useState(
    assistant?.ssml_data?.gender?.toLowerCase() ?? "male"
  );
  const [filteredVoices, setFilteredVoices] = useState([]);
  const themeMode = useSelector((state) => state.pageDetails.themeMode);
  const getAudioUrl = (lang, label, voice_recording_id, ElevenLabs = false) => {
    return ElevenLabs
      ? `https://voicegenie-saas-public-resources.s3.ap-south-1.amazonaws.com/Assets/Accents/ElevenLabs/${voice_recording_id}.mp3`
      : `https://voicegenie-saas-public-resources.s3.ap-south-1.amazonaws.com/Assets/Accents/${label}/${lang}_${label}.mp3`;
  };

  const theme = useTheme();

  const [additionalConfigObj, setAdditionalConfigObj] = useState({
    interruption: assistant?.additionalConfig?.interruption ?? true,
    brand_name: assistant?.additionalConfig?.brand_name ?? "deepgram",
    delay_callOpeningMessage:
      assistant?.additionalConfig?.delay_callOpeningMessage ?? false,
  });

  const [advancedSettings, setAdvancedSettings] = useState({
    max_duration: assistant?.ssml_data?.max_duration ?? 900,
    rengage_caller: assistant?.ssml_data?.rengage_caller ?? false,
    rengage_count: assistant?.ssml_data?.rengage_count ?? 2,
    no_input: assistant?.ssml_data?.no_input ?? 15,
    speech_rate: assistant?.ssml_data?.speech_rate ?? 15,
    rengage_message:
      assistant?.ssml_data?.rengage_message ??
      `Sorry, I didn't get any response from you, are you still there?`,
  });

  useEffect(() => {
    if (_.isEmpty(updatedData) && !_.isEmpty(assistant)) {
      setUpdatedData(assistant?.ssml_data);
      setSelectedGender(assistant?.ssml_data?.gender?.toLowerCase() ?? "male");
      setSelectedLanguage(assistant?.ssml_data?.lang?.toLowerCase() ?? "en");
      setAdditionalConfigObj({
        interruption: assistant?.additionalConfig?.interruption ?? true,
        brand_name: assistant?.additionalConfig?.brand_name ?? "deepgram",
        delay_callOpeningMessage:
          assistant?.additionalConfig?.delay_callOpeningMessage ?? false,
      });
      setAdvancedSettings({
        max_duration: assistant?.ssml_data?.max_duration ?? 900,
        rengage_caller: assistant?.ssml_data?.rengage_caller ?? false,
        rengage_count: assistant?.ssml_data?.rengage_count ?? 2,
        no_input: assistant?.ssml_data?.no_input ?? 15,
        speech_rate: assistant?.ssml_data?.speech_rate ?? 15,
        rengage_message:
          assistant?.ssml_data?.rengage_message ??
          `Sorry, I didn't get any response from you, are you still there?`,
      });
    }
  }, [assistant]);

  const handleChangeInterruptionSettings = (event) => {
    let { name, value } = event.target;

    setAdditionalConfigObj((prevState) => {
      if (name === "delay_callOpeningMessage") {
        value = value === "true";
      }
      const updatedConfig = { ...prevState, [name]: value };
      handleChange({
        additionalConfig: {
          ...assistant?.additionalConfig,
          ...updatedConfig,
        },
      });

      return updatedConfig;
    });
  };

  const handleClonedVoiceAgreement = () => {
    setDataInLocalStorage(
      LOCAL_STORAGE.ELEVEN_LABS_CLONED_AGREEMENT,
      !elevenlabsClonedVoiceAgreement
    );
    setElevenlabsClonedVoiceAgreement(!elevenlabsClonedVoiceAgreement);
  };

  const handleInputChange = (value) => {
    const [label, tts_provider] = value.split("|");

    if (label && selectedGender && selectedLanguage) {
      const ssml_data = filteredVoices?.find(
        (item) => item.label === label && item.tts_provider === tts_provider
      );
      if (ssml_data) {
        const audioUrl =
          tts_provider === "elevenlabs"
            ? getAudioUrl(
                ssml_data?.lang,
                label,
                ssml_data?.voice_recording_id,
                true
              )
            : getAudioUrl(ssml_data?.lang, label);
        setSampleAudioUrl(audioUrl);
        const { language, ...updated_ssml_data } = ssml_data;
        setUpdatedData({
          ...updated_ssml_data,
          ...advancedSettings,
        });
        handleChange({
          ssml_data: {
            ...updated_ssml_data,
            ...advancedSettings,
          },
        });
        handleChange({ gender: selectedGender });
      }
    }
  };

  const marks = [
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  const handleSaveLangChange = (lang) => {
    const langFull =
      LANGUAGES_LIST?.find((fullname) => fullname.value === lang)?.label ?? "";
    setUpdatedData((prev) => {
      return {
        ...prev,
        lang,
        country: LANG_COUNTRY[lang] || "",
        langFull,
      };
    });

    handleChange({
      ssml_data: {
        ...updatedData,
        lang,
        country: LANG_COUNTRY[lang] || "",
        langFull,
      },
    });
  };

  const handleSelectedAccent = () => {
    const matched = filteredVoices?.find(
      (voice) =>
        voice.label === updatedData?.label &&
        voice.tts_provider === updatedData?.tts_provider
    );
    // Return the combined value if a match is found, otherwise return an empty string
    return matched ? `${matched.label}|${matched.tts_provider}` : "";
  };

  const handleLangChange = (lang) => {
    setSelectedLanguage(lang);
    window.gtag("event",GTAGS.ASSISTANT_LANGUAGE_CHANGED,{
      userID:user.userID,
      workspaceID : workspaceId,
      Language: lang,
    })
    const newUpdatedLang = extractAndConcatenateFilterVoices(lang);
    const matched = newUpdatedLang?.filter(
      (voice) => voice.label === updatedData?.label
    );
    const matchingValue = matched.length > 0 ? updatedData?.label : "";
    if (matchingValue.length > 0) {
      handleSaveLangChange(lang);
    }
  };

  const extractAndConcatenateFilterVoices = (selectedLanguage) => {
    let multi_Lang_Code_Array = multiLangCodes[
      selectedGender.toLowerCase()
    ]?.filter((voice) => voice.language?.includes(selectedLanguage));
    let country_Code = LANG_COUNTRY[selectedLanguage] || "";
    multi_Lang_Code_Array = multi_Lang_Code_Array?.map((item) => ({
      ...item,
      lang: selectedLanguage,
      country: country_Code,
    }));
    setFilteredVoices(multi_Lang_Code_Array);
    return multi_Lang_Code_Array;
  };

  const handleChangeAdvanceSettings = (name, newValue) => {
    setAdvancedSettings((prevSettings) => ({
      ...prevSettings,
      [name]: newValue,
    }));
    setUpdatedData((prev) => ({
      ...prev,
      ...advancedSettings,
      [name]: newValue,
    }));
    handleChange({
      ssml_data: {
        ...updatedData,
        ...advancedSettings,
        [name]: newValue,
      },
    });
  };

  function transformVoicesArray(voicesArray) {
    return voicesArray.map((voice) => {
      let voice_recording_id = undefined;
      let preview_url = undefined;
      if (
        MULTI_LANG_CODES[voice.labels.gender] &&
        MULTI_LANG_CODES[voice.labels.gender].voice_recording_id
      )
        voice_recording_id =
          MULTI_LANG_CODES[voice_recording_id.labels.gender].voice_recording_id;
      if (
        MULTI_LANG_CODES[voice.labels.gender] &&
        MULTI_LANG_CODES[voice.labels.gender].preview_url
      )
        preview_url =
          MULTI_LANG_CODES[voice_recording_id.labels.gender].preview_url;
      return {
        vtype: voice.voice_id,
        label: voice.name,
        high_quality_base_model_ids: voice.high_quality_base_model_ids || [],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: voice.labels.gender ?? selectedGender,
        tts_provider: "elevenlabs",
        category: voice.category,
        voice_recording_id,
        preview_url: preview_url,
      };
    });
  }

  function addVoicesToMultiLangCodes(voicesArray, currentMultiLangCodes) {
    const transformedVoices = transformVoicesArray(voicesArray);

    const updatedMultiLangCodes = { ...currentMultiLangCodes };

    transformedVoices.forEach((voice) => {
      // Determine the target arrays for male and female based on the category
      const addToBothGenders =
        voice.category === "cloned" || voice.category === "professional";

      if (addToBothGenders) {
        // Add to both male and female
        [updatedMultiLangCodes.male, updatedMultiLangCodes.female].forEach(
          (targetArray) => {
            const isVoicePresent = targetArray.some(
              (existingVoice) => existingVoice.vtype === voice.vtype
            );
            if (!isVoicePresent) {
              targetArray.push(voice);
            }
          }
        );
      } else {
        // Regular case: Add to male or female based on the gender
        const targetArray =
          voice.gender === "male"
            ? updatedMultiLangCodes.male
            : voice.gender === "female"
            ? updatedMultiLangCodes.female
            : [];

        const isVoicePresent = targetArray.some(
          (existingVoice) => existingVoice.vtype === voice.vtype
        );

        if (!isVoicePresent) {
          targetArray.push(voice);
        }
      }
    });

    return updatedMultiLangCodes;
  }

  const handleGenderChanges = (e) => {
    e.stopPropagation();
    window.gtag("event",GTAGS.ASSISTANT_GENDER_CAHNGED,{
      userID:user.userID,
      workspaceID : workspaceId,
      Gender: e.target.value,
    })
    setSelectedGender(e.target.value);
  };

  function checkLabelInMultiLangCodes(multiLangCodes, ssmlData) {
    const gender = ssmlData.gender ?? selectedGender; // Use gender from ssml_data
    const ssmlLabel = ssmlData.label;
    // Check if the gender exists in MULTI_LANG_CODES
    if (multiLangCodes[gender]) {
      // Iterate through the corresponding gender's language data
      const languages = multiLangCodes[gender];
      // Check if any of the labels match the ssml_data label
      const labelMatch = languages.some((entry) => entry.label === ssmlLabel);
      return labelMatch; // Return true if a match is found
    }
    return false; // Return false if the gender doesn't exist or no label matches
  }

  useEffect(() => {
    if (assistant.ssml_data) {
      const isLabelPresent = checkLabelInMultiLangCodes(
        multiLangCodes,
        assistant.ssml_data
      );

      if (
        (assistant.ssml_data?.category === "cloned" ||
          assistant.ssml_data?.category === "professional") &&
        isLabelPresent
      ) {
        setUpdatedData((prev) => {
          return {
            ...prev,
            gender: selectedGender,
          };
        });
        handleChange({
          ssml_data: {
            ...updatedData,
            gender: selectedGender,
          },
        });
      } else if (assistant.ssml_data.gender !== selectedGender) {
        setIsChanged(false);
      }
    }
  }, [selectedGender]);

  const handleGetElevenLabGeneratedVoices = () => {
    if (!installedAppNameArray?.includes("elevenLabs")) {
      enqueueSnackbar("Please Integrate ElevenLabs First!", {
        variant: "error",
      });
      return;
    }
    dispatch(getElevenLabVoices())
      .unwrap()
      .then((res) => {
        if (res?.data?.voices) {
          const updatedMultiLangCodes = addVoicesToMultiLangCodes(
            res.data.voices,
            multiLangCodes
          );
          setMultiLangCodes(updatedMultiLangCodes);
          enqueueSnackbar("Generated Voices Imported Successfully!", {
            variant: "success",
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    extractAndConcatenateFilterVoices(selectedLanguage);
  }, [selectedGender, multiLangCodes]);

  useEffect(() => {
    setElevenlabsClonedVoiceAgreement(
      getDataFromLocalStorage(LOCAL_STORAGE.ELEVEN_LABS_CLONED_AGREEMENT) ||
        false
    );
  }, []);

  useEffect(() => {
    if (updatedData?.label) {
      // const audioUrl = getAudioUrl(updatedData?.lang, updatedData?.label);
      const audioUrl =
        updatedData?.tts_provider === "elevenlabs"
          ? getAudioUrl(
              updatedData.lang,
              updatedData.label,
              updatedData.voice_recording_id,
              true
            )
          : getAudioUrl(updatedData.lang, updatedData.label);
      setSampleAudioUrl(audioUrl);
    }
  }, [updatedData]);

  return (
    Object.keys(assistant).length !== 0 && (
      <Grid
        container
        xs={12}
        sx={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "space-between",
        }}
      >
        <Grid container item xs={6} pr={1}>
          <Card
            sx={{
              pt: 2,
              pb: 4,
              boxShadow: "0px 0px 1px 0px",
              minHeight: "450px",
            }}
          >
            <Grid item xs={12} paddingInline={3} mb={2}>
              <Typography variant="h6" fontWeight="bold" align="start">
                Details
              </Typography>
            </Grid>
            <Grid
              container
              xs={12}
              paddingInline={2}
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  display="flex"
                  marginLeft={1}
                  alignItems="center"
                  variant="subtitle1"
                >
                  Name :
                  <CustomHintPopover
                    size="small"
                    maxWidth={400}
                    transformHorizontalPosition={"left"}
                    transformVerticalPosition={"center"}
                    anchorHorizontalPosition={"right"}
                    anchorVerticalPosition={"top"}
                    hintContent={
                      "Choose a name that suits the personality of your assistant. The assistant would call themself by this name during a conversation."
                    }
                  />
                </Typography>
                <TextField
                  placeholder="eg : Steffan"
                  value={assistant?.name}
                  onChange={(e) =>{ handleChange({ name: e.target.value });
                window.gtag("event",GTAGS.ASSISTANT_NAME_CHANGED,{
                  userID:user.userId,
                })
                }}
                  fullWidth
                  sx={{ marginTop: 0.5, marginBottom: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  display="flex"
                  marginLeft={1}
                  alignItems="center"
                  variant="subtitle1"
                >
                  Company :
                  <CustomHintPopover
                    size="small"
                    maxWidth={400}
                    transformHorizontalPosition={"left"}
                    transformVerticalPosition={"center"}
                    anchorHorizontalPosition={"right"}
                    anchorVerticalPosition={"top"}
                    hintContent={
                      "Enter the name of the company or business which the AI assistant represents."
                    }
                  />
                </Typography>
                <TextField
                  // label="Company Name"
                  placeholder="eg : Voicegenie"
                  value={assistant?.companyName}
                  onChange={(e) =>{ handleChange({ companyName: e.target.value });
                  window.gtag("event",GTAGS.ASSISTANT_COMAPANY_NAME_CHANGED,{
                    userID:user.userId,
                  })
                  }}
                  fullWidth
                  sx={{ marginTop: 0.5, marginBottom: 2 }}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <Typography
                  display="flex"
                  marginLeft={1}
                  alignItems="center"
                  variant="subtitle1"
                >
                  Goal :
                  <CustomHintPopover
                    size="small"
                    maxWidth={400}
                    transformHorizontalPosition={"left"}
                    transformVerticalPosition={"center"}
                    anchorHorizontalPosition={"right"}
                    anchorVerticalPosition={"top"}
                    hintContent={
                      "This will be the main objective that your AI assistant will try to achieve once its in conversation with your contacts."
                    }
                  />
                </Typography>
                <TextField
                  value={assistant?.goal ?? ""}
                  placeholder="eg : Lead generation"
                  onChange={(e) =>{ handleChange({ goal: e.target.value });
                  window.gtag("event",GTAGS.ASSISTANT_GOAL_CHANGED,{
                    userID:user.userId,
                  })
                  }}
                  fullWidth
                  multiline
                  minRows={3}
                  variant="outlined"
                  sx={{ marginTop: 0.5 }}
                  inputProps={{ maxLength: 1000 }}
                />
                {!assistant.goal?.trim() && (
                  <Typography
                    paddingInline={2}
                    variant="subtitle2"
                    color="error"
                  >
                    Assistant's Goal is required !
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6} pl={1}>
          <Paper
            sx={{ padding: 2, boxShadow: "0px 0px 1px 0px", height: "100%" }}
          >
            <Grid item xs={12} paddingInline={1} mb={4}>
              <Typography variant="h6" fontWeight="bold" align="start">
                Voice Settings
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid container item xs={12} justifyContent="space-between">
                  <Grid
                    container
                    paddingInline={1}
                    justifyContent="space-between"
                  >
                    <Grid item display="flex" xs={5} alignItems="center">
                      <Typography
                        display="flex"
                        alignItems="center"
                        variant="subtitle1"
                        align="center"
                      >
                        Chosen Accent :
                        <CustomHintPopover
                          size="small"
                          maxWidth={400}
                          transformHorizontalPosition={"left"}
                          transformVerticalPosition={"center"}
                          anchorHorizontalPosition={"right"}
                          anchorVerticalPosition={"top"}
                          hintContent={
                            "Refer to the specific pronunciation patterns and speech characteristics that the AI bot adopts to sound like a native speaker in chosen language."
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={7} display="flex" alignItems="center">
                      <Typography variant="h6">
                        {updatedData?.label ?? ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                xs={12}
                marginBlock={3}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={5.5} mb={2}>
                  <Typography
                    display="flex"
                    mb={0.5}
                    ml={1}
                    alignItems="center"
                    variant="subtitle1"
                  >
                    Language :
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={
                        "This will be the language in which the bot will interact with the contact. Note : Please keep the text of Call Opening Message of the Assistant in the same language as chosen here for best results."
                      }
                    />
                  </Typography>
                  <Select
                    fullWidth
                    size="small"
                    value={selectedLanguage ?? assistant?.ssml_data?.lang}
                    onChange={(e) => handleLangChange(e.target.value)}
                  >
                    {LANGUAGES_LIST.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item container xs={5.5} mb={2}>
                  <Typography
                    display="flex"
                    mb={0.5}
                    ml={1}
                    alignItems="center"
                    variant="subtitle1"
                  >
                    Gender :
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"right"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={
                        "Choose assistant gender that fits the personality and role of your assistant"
                      }
                    />
                  </Typography>
                  <Select
                    fullWidth
                    size="small"
                    value={selectedGender ?? assistant?.ssml_data?.gender}
                    onChange={(e) => handleGenderChanges(e)}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {updatedData && (
                  <VoiceAssistant
                    gender={assistant?.gender}
                    updatedData={updatedData}
                    selectedLanguage={selectedLanguage}
                    selectedGender={selectedGender}
                    handleInputChange={handleInputChange}
                    handleChange={handleChange}
                    getAudioUrl={getAudioUrl}
                    filteredVoices={filteredVoices}
                    handleSelectedAccent={handleSelectedAccent}
                    handleGetElevenLabGeneratedVoices={
                      handleGetElevenLabGeneratedVoices
                    }
                  />
                )}
              </Grid>
              {/* {updatedData?.tts_provider === "elevenlabs" &&
              handleSelectedAccent() !== "" ? (
                <BaseModelMenu
                  updatedData={updatedData}
                  handleChange={handleChange}
                  setUpdatedData={setUpdatedData}
                  selectedLanguage={selectedLanguage}
                  setIsChanged={setIsChanged}
                />
              ) : null} */}

              {!abortSaving &&
              updatedData?.tts_provider === "elevenlabs" &&
              (updatedData?.category === "cloned" ||
                updatedData.category === "professional") &&
              installedAppNameArray?.includes("elevenLabs") ? (
                <Grid
                  container
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                >
                  <Grid item xs={1}>
                    <Checkbox
                      checked={elevenlabsClonedVoiceAgreement}
                      onChange={handleClonedVoiceAgreement}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body2" color="error" fontWeight="bold">
                      I hereby confirm that I possess all necessary rights or
                      consents to use this cloned voice sample and commit to not
                      using it for any unlawful, fraudulent, or harmful purposes
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              {updatedData?.tts_provider === "elevenlabs" &&
              installedAppNameArray?.includes("elevenLabs") &&
              handleSelectedAccent() !== "" ? (
                <GenerateElevenLabsAudioSample
                  setSampleAudioUrl={setSampleAudioUrl}
                  updatedData={updatedData}
                  sampleAudioLoading={sampleAudioLoading}
                  setSampleAudioLoading={setSampleAudioLoading}
                />
              ) : null}

              <Grid container marginTop={3}>
                <Grid item xs={5} display="flex" alignItems="center" pl={1}>
                  <Typography
                    display="flex"
                    alignItems="flex-end"
                    variant="subtitle1"
                  >
                    Accent Sample Audio :
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={"Sample audio of the chosen Accent."}
                    />
                  </Typography>
                </Grid>
                {sampleAudioUrl &&
                  (sampleAudioLoading ? (
                    <Grid item xs={7}>
                      <div
                        style={{
                          backgroundColor: "#f0f0f0",
                          height: "52px",
                          borderRadius: "24px",
                          position: "relative",
                          marginBottom: "12px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#d3d3d3",
                            height: "8px",
                            width: "80%",
                            borderRadius: "4px",
                            position: "absolute",
                            top: "23px",
                            left: "30px",
                          }}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <Grid item xs={7}>
                      <audio
                        controls
                        key={sampleAudioUrl}
                        style={{ flex: 1, width: "100%" }}
                        src={sampleAudioUrl}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid container xs={12} mt={2}>
          <Card
            sx={{
              width: "100%",
              pt: 2,
              pb: 4,
              paddingInline: 2,
              boxShadow: "0px 0px 1px 0px",
              minHeight: "450px",
            }}
          >
            <Grid
              item
              xs={12}
              paddingInline={3}
              mb={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" fontWeight="bold" align="start">
                Advanced Settings
              </Typography>
              <Button
                variant="contained"
                size="small"
                sx={{ minWidth: 100, textTransform: "none" }}
                onClick={() => {
                  const newDefaultValues = {
                    max_duration: 900,
                    rengage_caller: false,
                    rengage_count: 2,
                    no_input: 15,
                    speech_rate: 15,
                    rengage_message:
                      "Sorry, I didn't get any response from you, are you still there?",
                  };
                  setAdvancedSettings({
                    ...newDefaultValues,
                  });
                  handleChange({
                    ssml_data: { ...updatedData, ...newDefaultValues },
                  });
                }}
              >
                Use Default Values
              </Button>
            </Grid>

            <Grid
              container
              xs={12}
              paddingInline={2}
              alignItems="center"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid container item xs={12}>
                <Grid item xs={5}>
                  <Typography
                    display="flex"
                    alignItems="center"
                    variant="subtitle1"
                  >
                    Max Call Duration :
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={
                        "Set a time limit for your calls. The voice assistant will end the call once this duration is reached. "
                      }
                    />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    display="flex"
                    alignItems="center"
                    fontWeight="bold"
                  >
                    ({advancedSettings.max_duration / 60} minutes)
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Slider
                    valueLabelDisplay="auto"
                    value={advancedSettings.max_duration / 60}
                    step={1}
                    onChange={(e, newValue) =>
                      handleChangeAdvanceSettings("max_duration", newValue * 60)
                    }
                    aria-labelledby="max-duration-slider"
                    min={5}
                    max={20}
                  />
                  <Box
                    mb={2}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="caption" sx={{ cursor: "pointer" }}>
                      5
                    </Typography>
                    <Typography variant="caption" sx={{ cursor: "pointer" }}>
                      20
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={5}>
                  <Typography
                    display="flex"
                    alignItems="center"
                    variant="subtitle1"
                  >
                    Speech Rate :
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={
                        "Adjust how fast or slow the voice assistant talks. Set it to your perfect speed for an enjoyable listening experience ! "
                      }
                    />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    display="flex"
                    alignItems="center"
                    fontWeight="bold"
                  >
                    ( {advancedSettings.speech_rate} )
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Slider
                    valueLabelDisplay="auto"
                    value={advancedSettings.speech_rate}
                    onChange={(e, newValue) =>
                      handleChangeAdvanceSettings("speech_rate", newValue)
                    }
                    aria-labelledby="speech-rate-slider"
                    min={-20}
                    max={20}
                  />
                  <Box
                    mb={2}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="caption" sx={{ cursor: "pointer" }}>
                      -20
                    </Typography>
                    <Typography variant="caption" sx={{ cursor: "pointer" }}>
                      20
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={5}>
                  <Typography
                    display="flex"
                    alignItems="center"
                    variant="subtitle1"
                  >
                    No Response Time Limit :
                    <CustomHintPopover
                      size="small"
                      maxWidth={400}
                      transformHorizontalPosition={"left"}
                      transformVerticalPosition={"center"}
                      anchorHorizontalPosition={"right"}
                      anchorVerticalPosition={"top"}
                      hintContent={
                        "Set how long the assistant waits for the user to respond during a call. If the user doesn't reply within this time, the call will end (unless re-engagement settings are on, in which case the assistant will attempt to re-engage with the user) . "
                      }
                    />
                  </Typography>
                  <Typography variant="subtitle2" fontWeight="bold">
                    ( {advancedSettings.no_input} seconds)
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Slider
                    valueLabelDisplay="auto"
                    disabled={!advancedSettings.no_input}
                    value={advancedSettings.no_input}
                    onChange={(e, newValue) =>
                      handleChangeAdvanceSettings("no_input", newValue)
                    }
                    aria-labelledby="no_input-slider"
                    min={15}
                    max={30}
                  />
                  <Box
                    mb={2}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="caption" sx={{ cursor: "pointer" }}>
                      15
                    </Typography>
                    <Typography variant="caption" sx={{ cursor: "pointer" }}>
                      30
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              marginBlock={1}
              display="flex"
              alignItems="center"
              paddingInline={2}
            >
              <Grid item xs={5} alignItems="center">
                <Typography
                  display="flex"
                  variant="subtitle1"
                  alignItems="center"
                >
                  Assistant's Response Behavior:
                  <CustomHintPopover
                    size="small"
                    maxWidth={400}
                    transformHorizontalPosition={"left"}
                    transformVerticalPosition={"center"}
                    anchorHorizontalPosition={"right"}
                    anchorVerticalPosition={"top"}
                    hintContent={
                      <>
                        <strong>Rapid Response:</strong>
                        <p>
                          Assistant will start speaking as soon as silence is
                          detected from the caller's end, without checking if
                          the customer has fully conveyed their message.This
                          will have quicker responses from the assistant.
                        </p>
                        <br />
                        <strong>
                          Thoughtful Response (Contextual Precision) :
                        </strong>
                        <p>
                          The assistant will try to respond back prioritizing
                          what the customer is trying to say over silence
                          detected while the caller is speaking.On an average
                          this will make assistant's responses slower than Rapid
                          response.
                        </p>
                      </>
                    }
                  />
                </Typography>
              </Grid>
              <Grid item>
                <RadioGroup
                  row
                  aria-labelledby="brandname-controlled-radio-buttons-group"
                  name="brand_name"
                  value={additionalConfigObj.brand_name ?? "deepgram"}
                  onChange={handleChangeInterruptionSettings}
                >
                  <FormControlLabel
                    sx={{ minWidth: "200px" }}
                    value="deepgram"
                    control={<Radio />}
                    label="Rapid Response"
                  />
                  <FormControlLabel
                    sx={{ minWidth: "200px" }}
                    value="azure"
                    control={<Radio />}
                    label="Thoughtful Response  (Contextual Precision)"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              marginTop={2}
              marginBottom={1}
              paddingInline={2}
            >
              <Grid item xs={5} display="flex" alignItems="center">
                <Typography
                  display="flex"
                  variant="subtitle1"
                  alignItems="center"
                >
                  Allow Assistant Interruption :
                  <CustomHintPopover
                    size="small"
                    maxWidth={400}
                    transformHorizontalPosition={"left"}
                    transformVerticalPosition={"center"}
                    anchorHorizontalPosition={"right"}
                    anchorVerticalPosition={"top"}
                    hintContent={
                      "If turned on, we are allowing callers to interrupt the assistant while the assistant is speaking. Once interrupted the assistant will stop speaking and listen to what the caller is saying."
                    }
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={additionalConfigObj.interruption}
                  onChange={(e) =>
                    handleChangeInterruptionSettings({
                      target: { name: "interruption", value: e.target.checked },
                    })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              marginBlock={1}
              display="flex"
              alignItems="center"
              paddingInline={2}
            >
              <Grid item xs={5} alignItems="center">
                <Typography
                  display="flex"
                  variant="subtitle1"
                  alignItems="center"
                >
                  Who Speaks First:
                  <CustomHintPopover
                    size="small"
                    maxWidth={400}
                    transformHorizontalPosition={"left"}
                    transformVerticalPosition={"center"}
                    anchorHorizontalPosition={"right"}
                    anchorVerticalPosition={"top"}
                    hintContent={
                      <>
                        <strong>
                          This setting allows you to control the flow of your
                          calls.
                        </strong>
                        <br />
                        <p>Choose who should start the conversation:</p>
                        <br />
                        <strong>User Initiates :</strong>
                        <p>
                          The Assistant will remain silent until the user speaks
                          first, giving the user full control over the call's
                          start.
                        </p>
                        <br />
                        <strong>Assistant Initiates :</strong>
                        <p>
                          The Assistant will begin the call with the call
                          opening message, setting the tone from the start.
                        </p>
                        <br />
                        <strong>
                          Note: This feature is currently in Beta.
                        </strong>
                      </>
                    }
                  />
                  <CustomHintPopover
                    icon={
                      <Chip
                        size="small"
                        label="Beta"
                        color="primary"
                        sx={{
                          borderRadius: "16px",
                          paddingBloack: 0,
                        }}
                      />
                    }
                    showIcon={false}
                    size="small"
                    maxWidth={400}
                    transformHorizontalPosition={"left"}
                    transformVerticalPosition={"center"}
                    anchorHorizontalPosition={"right"}
                    anchorVerticalPosition={"top"}
                    hintContent={
                      <>
                        <strong>
                          This setting allows you to control the flow of your
                          calls.
                        </strong>
                        <br />
                        <p>Choose who should start the conversation:</p>
                        <br />
                        <strong>User Initiates :</strong>
                        <p>
                          The Assistant will remain silent until the user speaks
                          first, giving the user full control over the call's
                          start.
                        </p>
                        <br />
                        <strong>Assistant Initiates :</strong>
                        <p>
                          The Assistant will begin the call with the call
                          opening message, setting the tone from the start.
                        </p>
                        <br />
                        <strong>
                          Note: This feature is currently in Beta.
                        </strong>
                      </>
                    }
                  />
                </Typography>
              </Grid>
              <Grid item>
                <RadioGroup
                  aria-labelledby="brandname-controlled-radio-buttons-group"
                  name="delay_callOpeningMessage"
                  value={additionalConfigObj.delay_callOpeningMessage ?? false}
                  onChange={handleChangeInterruptionSettings}
                >
                  <FormControlLabel
                    sx={{ minWidth: "200px" }}
                    value={true}
                    control={<Radio />}
                    label="User Initiate : Assistant remains silent until user speaks first."
                  />
                  <FormControlLabel
                    sx={{ minWidth: "200px" }}
                    value={false}
                    control={<Radio />}
                    label="Assistant Initiate : Assistant begins with your Call Opening Message."
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container xs={12} justifyContent="center" marginBottom={1}>
              <VoiceMail assistant={assistant} handleChange={handleChange} />
            </Grid>
            <Grid container xs={12} justifyContent="center" marginTop={2}>
              <Accordion
                defaultExpanded
                expanded={advancedSettings.rengage_caller}
                style={{
                  width: "100%",
                  paddingInline: 2,
                  borderRadius: 24,
                  boxShadow: "0px 0px 1px 0px",
                }}
              >
                <AccordionSummary>
                  <Grid
                    xs={12}
                    item
                    container
                    sx={{ height: "60px" }}
                    alignItems="center"
                    border={0}
                    borderRadius={1}
                    borderColor={theme.palette.divider}
                    paddingInline={2}
                  >
                    <Grid item xs={6}>
                      <Typography
                        display="flex"
                        variant="subtitle1"
                        alignItems="center"
                        fontWeight="bold"
                      >
                        Re-engage User
                        <CustomHintPopover
                          size="small"
                          maxWidth={400}
                          transformHorizontalPosition={"left"}
                          transformVerticalPosition={"center"}
                          anchorHorizontalPosition={"right"}
                          anchorVerticalPosition={"top"}
                          hintContent={
                            <p>
                              Enable this setting to allow the assistant to
                              re-engage with the user if the user has not
                              responded after{" "}
                              <strong>No Response Time Limit</strong>. has
                              passed. Activate this to configure re-engagement
                              options.
                            </p>
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                      <Switch
                        checked={advancedSettings.rengage_caller}
                        onChange={(e, newValue) =>
                          handleChangeAdvanceSettings(
                            "rengage_caller",
                            newValue
                          )
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container item xs={12} paddingInline={2}>
                    <Grid container item xs={12}>
                      <Grid item xs={5}>
                        <Typography
                          display="flex"
                          alignItems="center"
                          variant="subtitle1"
                        >
                          Re-engage Count :
                          <CustomHintPopover
                            size="small"
                            maxWidth={400}
                            transformHorizontalPosition={"left"}
                            transformVerticalPosition={"center"}
                            anchorHorizontalPosition={"right"}
                            anchorVerticalPosition={"top"}
                            hintContent={
                              <>
                                Set the number of times the assistant will try
                                to re-engage with the user after waiting for the{" "}
                                <strong>No Response Time Limit</strong> to pass
                                each time. If this count is exceeded and the
                                user still does not respond, the call will end."
                              </>
                            }
                          />
                        </Typography>
                        <Typography variant="subtitle2" fontWeight="bold">
                          ( {advancedSettings.rengage_count} )
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Slider
                          step={1}
                          marks={marks}
                          valueLabelDisplay="auto"
                          disabled={!advancedSettings.rengage_caller}
                          value={advancedSettings.rengage_count}
                          onChange={(e, newValue) =>
                            handleChangeAdvanceSettings(
                              "rengage_count",
                              newValue
                            )
                          }
                          aria-labelledby="loudness-slider"
                          min={2}
                          max={5}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item xs={5}>
                        <Typography display="flex" variant="subtitle1">
                          Re-engage Message :
                          <CustomHintPopover
                            size="small"
                            maxWidth={400}
                            transformHorizontalPosition={"left"}
                            transformVerticalPosition={"center"}
                            anchorHorizontalPosition={"right"}
                            anchorVerticalPosition={"top"}
                            hintContent={
                              <>
                                This is the message the assistant will use to
                                re-engage with user if user haven't responded
                                after <strong>No Response Time Limit</strong>.
                              </>
                            }
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          value={advancedSettings.rengage_message}
                          placeholder={`eg : Sorry, I didn't get any response from you, are you still there?`}
                          onChange={(e) =>
                            handleChangeAdvanceSettings(
                              "rengage_message",
                              e.target.value
                            )
                          }
                          fullWidth
                          multiline
                          rows={2}
                          variant="outlined"
                          sx={{ marginTop: 0.5 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    )
  );
};

export default CustomizationComponent;
